import { gql, useQuery } from "@apollo/client";
import { settings } from "../../../config";

const GET_INFO = gql`
  query MyQuery {
    us {
      pictureOfMe {
        url
      }
      whoAmI
    }
  }
`;

export default function Information() {
  const { loading, error, data } = useQuery(GET_INFO);

  // Check if data is still loading
  if (loading) {
    return <p>Loading...</p>;
  }

  // Check if there's an error
  if (error) {
    return <p>Error: {error.message}</p>;
  }


  return (
    <>
      {data.us.map((us, index) => 
                <div key={index} className='absolute flex flex-col lg:flex-row items-center gap-7 w-[90%] left-[7.5%] top-[22%]'>
                  <div className="text-gray-nav w-full lg:w-1/2">
                      <p className="mt-12 text-sm md:text-base">I am Mauro Coone, a motivated and dedicated 22-year-old with a passion for both sports and youth movement. My experience in the game of football and fulfilling a leadership role at the local Chiro has taught me teamwork and communication skills. Full of enthusiasm to make my mark in the world of ICT, I am determined to secure a rewarding position that will enable personal and professional growth.</p>
                      <a href={`${settings.BASE_PATH}/assets/images/cv.pdf`} download="CV_Mauro-coone"><button className="download-button">Curriculum Vitae</button></a>
                  </div>
                  <div className="w-full lg:w-1/2">                  
                    <img className="w-[70%] lg:mx-auto rounded-xl" width={500} height={600} src={us.pictureOfMe.url} alt="picture_of_me" />
                  </div>
                </div>
            )}
    </>
  );
}

