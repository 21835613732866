import Technologies from '../components/layout/about/technologies';
import AboutText from '../components/layout/about/text';


export default function About() {
    return (
        <>
            <section className='bg-purple-dark pb-[5rem] md:pb-[10rem]'>
            <span id="overview" className='tab'>&nbsp;</span> 
                <div className='max-w-[90%] md:max-w-[80%] mx-auto'>
                    <AboutText />
                    <Technologies />
                </div>
            </section>
        </>
        
    )
}