import BlogText from "../components/layout/blog/text";
import Information from "../components/layout/blog/me";
import { Link } from "react-router-dom";


export default function BlogPage() {
    
    return (
        <div className="pb-[5rem] bg-purple-dark" id="about">
            <div className="max-w-[90%] md:max-w-[80%] mx-auto relative bg-purple-mid min-h-[100vh] md:min-h-[120vh] lg:min-h-[90vh] rounded-xl">
                <BlogText />
                <Information />
            </div>
        </div>
    )
}

