import { gql, useQuery } from "@apollo/client";
import { useEffect, useRef } from "react";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

export default function WorkText() {
    
    return (
        <>
            <div className='text-center mb-[5rem]'>
                <p className='uppercase text-gray-nav' id="work" >Bit of things I've accomplished</p>
                <h1 className='text-[3rem] w-[90%] mx-auto md:w-full md:text-[4.5rem] mt-[1rem] mb-[2rem]'>Work Experience.</h1>
            </div>
        </>
        
    )
}