import Globe from "../components/layout/contact/globe"
import FormContact from "../components/layout/contact/form"

export default function Contact() {
    return (
        <>
            <section className="bg-purple-dark pb-[5rem]" id="contact">
                <div className="max-w-[90%] md:max-w-[80%] mx-auto flex flex-col lg:flex-row gap-[2rem] h-[70vh]">
                    <FormContact />
                    <div className="relative w-[50%]">
                        <Globe />
                    </div>
                </div>
            </section>
        </>
        
    )
}