import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from './logo';
import LogoMobile from './logoMobile';
import { ROUTES } from '../../../routes';

export default function Navigation() {
  const [scrolling, setScrolling] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false); // State voor mobiele navigatie

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 120);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`fixed z-[999999] pt-5 pb-5 w-full transition-all duration-500 ${scrolling ? 'bg-purple-dark' : ''}`}>
      <div className='flex justify-between md:gap-12 lg:gap-0 items-center ml-[5%] w-[90%]'>
        <Logo />
        <ul className='hidden md:flex md:gap-4 lg:gap-8 xl:gap-16 text-gray-nav'>
          <li className='hover:text-white-text'>
            <a href="#about">About</a> {/* Zorg ervoor dat je id="about" hebt op de juiste sectie */}
          </li>
          <li className='hover:text-white-text'>
            <a href="#overview">Overview</a> {/* Zorg ervoor dat je id="overview" hebt op de juiste sectie */}
          </li>
          <li className='hover:text-white-text'>
            <a href="#work">Work</a> {/* Zorg ervoor dat je id="work" hebt op de juiste sectie */}
          </li>
          <li className='hover:text-white-text'>
            <a href="#contact">Contact</a> {/* Zorg ervoor dat je id="contact" hebt op de juiste sectie */}
          </li>
        </ul>

      </div>
    </nav>
  );
}
