// Haal de benodigde functionaliteiten op van het Apollo Client-pakket en React Router
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../routes';
// Importeer useRef-hook van React
import { useRef } from 'react';
// Importeer useGSAP-hook van GSAP om animaties te beheren
import { useGSAP } from '@gsap/react';
// Importeer de GSAP-bibliotheek
import gsap from 'gsap';
// Importeer de ScrollTrigger-plugin van GSAP
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Registreer de ScrollTrigger-plugin
gsap.registerPlugin(ScrollTrigger);

const GET_ABOUT = gql`
    query MyQuery {
        projects {
            id
            icon {
                url
            }
            picture {
                url
            }
            linkTo
            languages
            title
            productiedatum
        }
    }
`;

export default function Projects() {
    // Maak een verwijzing (ref) naar projectelementen aan
    const projectRefs = useRef([]);

    const { loading, error, data } = useQuery(GET_ABOUT);

    useGSAP(
        async () => {

            // Controleer of er geen laadstatus is en de gegevens zijn opgehaald
            if (!loading && data) {
                // wacht tot de content volledig is geladen
                await new Promise((resolve) => setTimeout(resolve, 0));
    
                // Itereer over elk projectelement en stel de initiële staat in
                projectRefs.current.forEach((ref) => {
                    gsap.set(ref, { opacity: 0, y: 100 });
                });
    
                // Animeer elk projectelement naar de eindstaat met een vertraging
                projectRefs.current.forEach((ref, index) => {
                    gsap.to(ref, {
                        opacity: 1,
                        y: 0,
                        duration: 1,
                        delay: index * 0.3, 
                        scrollTrigger: {
                            trigger: ref,
                            start: '20% 100%', 
                            toggleActions: 'play reset play reverse',
                        },
                    });
                });
            }
        },
        [loading, data, ScrollTrigger],
        { scope: projectRefs.current }
    );

    // Controleer of de gegevens nog steeds aan het laden zijn
    if (loading) {
        return <p>Loading...</p>;
    }
    // Check voor errors
    if (error) {
        return <p>Error: {error.message}</p>;
    }

    return (
        <section className='timeline md:grid md:grid-cols-2 md:gap-[0.5rem] lg:block'>
            {data.projects.map((project, index) => (
                <li
                    className='project relative mb-[3.5rem] list-none'
                    key={project.id}
                    ref={(ref) => (projectRefs.current[index] = ref)}
                >
                    {/* Use an anchor tag with the slug as the external link */}
                    <a
                        className='block lg:w-[26rem]'
                        href={`${project.linkTo}`} // Replace with the desired external URL format
                        target="_blank" // Opens the link in a new tab
                        rel="noopener noreferrer" // Best practice for security
                    >
                        <span className='hidden lg:block rounded-full absolute w-[80px] h-[80px] left-[46.3%] bg-purple-card border-4 border-white-text p-[15px]'>
                            <div>
                                <img className='w-[40px] h-[40px]' src={project.icon.url} alt={project.title} />
                            </div>
                        </span>
                        <div className='bg-purple-card md:w-[100%] lg:w-[26rem] rounded-xl border-b-2 z-[10]'>
                            <div className='hidden xl:block arrow absolute w-[30px] h-[30px] bg-purple-card rotate-45 right-[57%] top-[2rem] z-[2]'></div>
                            <div className='py-[1rem] px-[2rem]'>
                                <h3 className='text-2xl lg:text-3xl'>{project.title}</h3>
                                <p className='text-gray-nav text-sm md:text-xs lg:text-sm mb-[1rem]'>{project.languages}</p>
                                <img className='h-[12rem] w-full object-cover' width={300} height={100} src={project.picture.url} alt={project.title} />
                                <p className='date hidden lg:block text-gray-nav'>{project.productiedatum}</p>
                            </div>
                        </div>
                    </a>
                </li>
            ))}
        </section>
    );
    
}
