// import elements
import { Canvas } from '@react-three/fiber';
import { useEffect, useRef } from "react";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"; // Updated import

import Navigation from './navigation';
import Scroll from './scroll';
import HeaderText from './text';
import Bureau from './bureau.tsx';
import TopButton from '../top-button.js';
import SocialButton from '../socials-buttons.js';

gsap.registerPlugin(ScrollTrigger);

export default function Header() {

    const bureau = useRef();
    
    /* useGSAP(() => {
        // Set the initial state of the containers

        // Animate the containers to their final states with a delay
        gsap.to(bureau.current, {
            y: 200,
            duration: 1,
            delay: .5, 
            scrollTrigger: {
                trigger: bureau.current,
                start: "top 20%",
                end: "bottom 10%",
                toggleActions: "play reset play reverse",
                scrub: true,
            },
        });
    }, [], { scope: bureau.current });*/

    return (
        <>
          <header id='header' className='overflow-hidden header h-[117vh] '>
            <Navigation />
            <div className='absolute CanvasBureau w-full h-[40%] md:h-[60%] lg:h-full top-[20rem] lg:top-[8rem] z-[0]'>
              <Canvas ref={bureau}><Bureau /></Canvas>
            </div>
            <HeaderText />
            <Scroll />
            <TopButton />
          </header>
        </>
    )
}
