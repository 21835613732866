import { ROUTES } from "../../../routes";
import { Link } from 'react-router-dom';

import { settings } from "../../../config";

export default function Logo() {

  return (
    <div>
        <Link to={ROUTES.Home} className="flex gap-2 lg:gap-4 items-center">
        {/* Use the logo URL from the headers data */}
        <img className="h-10 w-12 object-contain" src={`${settings.BASE_PATH}/assets/images/logo.png`}  alt="logo-mauro" width={48} height={40} loading="lazy" decoding="async"/>
        {/* Use the title from the headers data */}
        <p className="text-white-text w-full">Webdesigner & Front-end Developer</p>
        </Link>
    </div>
  );
}
