import { gql, useQuery } from '@apollo/client';
import Projects from '../components/layout/work/projects';
import WorkText from '../components/layout/work/text';

export default function Work() {

    return (
        <>
            <section className='bg-purple-dark text-white-text pb-[10vh]' >
                <span id="work" className='md:tab'>&nbsp;</span> 
                <WorkText />
                <article className='relative w-[90%] lg:w-[90%] xl:w-[65%] mx-auto'> 
                    <Projects />
                </article>
            </section>
        </>
        
    )
}